@import '../../../../../shared/scss-helpers/mixins.scss';

.subscribeLinkGamesSales {
  &.subscribeLink {
    min-width: 147px;
    @media (min-width: 1440px) {
      font-size: 16px;
    }
  }

  .subscribeSalesText {
    min-width: 138px;

    @media (min-width: 1024px) {
      min-width: 172px;
    }
  }
  .subscribeBAUText {
    display: none;
  }
}

.appHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  padding: 0 var(--horizontal-spacing-2);
  height: var(--header-height);
  color: var(--color-tone-1);
  border-bottom: 1px solid var(--color-tone-4);

  button {
    cursor: pointer;
  }

  button.icon {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0 var(--horizontal-spacing-0-5);
  }

  button.icon:last-child {
    padding-right: 0;
  }
}

.subscribeLink {
  display: flex;
  align-items: center;
  justify-content: center;
  font: 14px/18px 'nyt-franklin-600';
  color: var(--color-tone-1);
  border: solid 1px var(--color-tone-1);
  border-radius: 9999px;
  width: 85px;
  height: 32px;
  padding: 0 var(--horizontal-spacing-1-5);
  -webkit-padding-start: 0;
  -webkit-padding-end: 0;
  background-color: var(--color-tone-7);

  @media (min-width: 375px) {
    width: 147px;
  }
  @media (min-width: 400px) {
    margin-left: calc(var(--horizontal-spacing-0-5) * 2.5);
  }

  @media (min-width: 1024px) {
    font: 16px/21px 'nyt-franklin-600';
    height: 44px;
    width: 172px;
    margin-left: var(--horizontal-spacing-1-5);
    padding: 0 var(--horizontal-spacing-2);
    min-height: 35px;
  }
}

.appHeader .title {
  flex-grow: 2;
  font-family: 'nyt-karnakcondensed';
  font-weight: 700;
  font-size: 28px;
  letter-spacing: 0.01em;
  text-align: left;
  left: 0;
  right: 0;
  pointer-events: none;
  position: relative;
}

.menuLeft {
  display: flex;
  margin: 0;
  padding: 0;
  align-items: center;
  justify-content: flex-start;
}

.menuRight {
  display: flex;
  justify-content: flex-end;

  button svg {
    vertical-align: middle;
  }

  :global(.pz-web) &.longTextVariant svg {
    @include phone {
      width: 20px;
    }
  }
}

#navButton {
  padding-top: calc(var(--vertical-spacing-0-5) * 0.5);
  padding-left: 0px;
  padding-right: var(--horizontal-spacing-1);
}

@media (min-width: 1024px) {
  .appHeader {
    .title {
      text-align: center;
      font-size: 36px;
    }

    button.icon {
      padding: 0 calc(var(--horizontal-spacing-0-5) * 1.5);
    }
  }
}

@media (min-width: 768px) {
  .appHeader {
    .title {
      text-align: center;
      font-size: 32px;
    }

    .menuLeft,
    .menuRight {
      // these two must be exactly the same width for the title to be centered
      width: 350px;
    }
  }

  @media (min-width: 1024px) {
    .appHeader .title {
      font-size: 36px;
    }
  }

  #navButton {
    padding-top: calc(var(--vertical-spacing-0-5) * 0.5);
    padding-left: 0px;
  }
}

.toolbar__menu {
  display: flex;
  align-items: center;
  height: 100%;
}

.adIcon {
  background: var(--ad-messaging-awareness) no-repeat center center;
  width: 26px;
  height: 24px;
  margin-right: var(--horizontal-spacing-1);
}
