@import 'shared/scss-helpers/mixins.scss';

$default-tooltip-width: 287px;

.popover {
  background-color: #2a2a2a;
  color: #f8f8f8;
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: unset;
  max-width: $default-tooltip-width;
  word-wrap: break-word;
  height: fit-content;
  padding: var(--vertical-spacing-0-5)
    calc(var(--horizontal-spacing-1-5) * 1.5)
    calc(var(--vertical-spacing-1-5) * 1.25);
  font-family: 'nyt-franklin';
  border-radius: 4px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);

  &__trigger {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    height: 100%;
  }

  &__close {
    // remove default button styling
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }

  &__x {
    @include flexbox($align: center, $justify: center);
    color: #f8f8f8;
    position: absolute;
    right: 12px;
    top: 12px;
    width: 24px;
    font-size: 24px;
    cursor: pointer;
  }

  &__pill {
    @include flexbox($align: center);
    width: max-content;
    border-color: #2a2a2a;
    padding: calc(var(--vertical-spacing-0-5) * 0.5)
      var(--horizontal-spacing-1-5);
  }

  &__feature_text {
    color: #2873dc; // TODO: replace with color token
    text-transform: uppercase;
    font-weight: 700;
    font-size: 11px;
  }

  &__text {
    font-size: 16px;
    line-height: 18px;
    margin: var(--vertical-spacing-0-5) 0;

    &--heading-big,
    &--heading-bold {
      font-weight: 700;
    }

    &--heading-big {
      font-size: 18px;
    }
  }

  &__headingContainer {
    @include flexbox(row, center, flex-start);

    &:has(.popover__text--heading-big) {
      margin-bottom: var(--vertical-spacing-1-5);
    }
  }
}
